<template>
    <div>
        <PageHeaderLayout>
            <div class="main-page-content">
                <el-row class="table-header">
                    <el-col>
                        <el-button type="primary" size="medium"
                                   v-if="userPermissions.indexOf('sys_user_manage_create') != -1 "
                                   @click="addButton(0)">添加
                        </el-button>
<!--                        <el-button type="primary" size="medium"-->
<!--                                   v-if="userPermissions.indexOf('sys_user_manage_create') != -1 "-->
<!--                                   @click="editButton()">修改密码-->
<!--                        </el-button>-->
                    </el-col>
                </el-row>

              <el-row class="table-search">
                <el-form :inline="true" :model="searchForm" class="demo-form-inline">
                  <el-form-item label="名称">
                    <el-input placeholder="请输入名称" clearable v-model="searchForm.username"/>
                  </el-form-item>
                  <el-form-item>
                    <el-button type="primary" @click="onSearchClick" style="margin-left: 12px">搜索</el-button>
                  </el-form-item>
                  <el-form-item>
                    <el-button @click="onResetSearch">重置</el-button>
                  </el-form-item>
                </el-form>
              </el-row>

                <ApeTable ref="apeTable" :data="customerList" :columns="columns" :loading="loadingStatus"
                          :pagingData="pagingData" @switchPaging="switchPaging" highlight-current-row>
                    <!--<el-table-column-->
                            <!--slot="first-column"-->
                            <!--width="80"-->
                            <!--align="center"-->
                            <!--label="Drag">-->
                        <!--<template slot-scope="scope">-->
                            <!--<el-tooltip effect="dark" content="拖动排序" placement="top-start">-->
                                <!--<span class="drag-handle" :data-id="scope.row.id"><i class="el-icon-rank"></i></span>-->
                            <!--</el-tooltip>-->
                        <!--</template>-->
                    <!--</el-table-column>-->
                    <!--<el-table-column-->
                            <!--v-if="buttonType=='icon'"-->
                            <!--label="操作">-->
                        <!--<template slot-scope="scope">-->
              <!--<span>-->
                <!--&lt;!&ndash;<el-tooltip effect="dark" content="修改密码" placement="top-start"&ndash;&gt;-->
                  <!--&lt;!&ndash;v-if="userPermissions.indexOf('sys_user_manage_edit') != -1">&ndash;&gt;-->
                  <!--&lt;!&ndash;<el-button size="mini" icon="el-icon-edit" @click="editButton(scope.row.id)"></el-button>&ndash;&gt;-->
                  <!--&lt;!&ndash;</el-tooltip>&ndash;&gt;-->
                <!--<el-tooltip effect="dark" content="删除" placement="top-start">-->
                  <!--<span>-->
                    <!--<el-popover-->
                            <!--v-if="userPermissions.indexOf('sys_user_manage_delete') != -1"-->
                            <!--placement="top"-->
                            <!--width="150"-->
                            <!--v-model="scope.row.visible">-->
                      <!--<p>确定要删除该用户吗？</p>-->
                      <!--<div style="text-align: right; margin: 0;">-->
                        <!--<el-button type="text" size="mini" @click="scope.row.visible=false">取消</el-button>-->
                        <!--<el-button type="danger" size="mini" @click="deleteButton(scope.row.id)">确定</el-button>-->
                      <!--</div>-->
                      <!--<el-button slot="reference" type="danger" size="mini" icon="el-icon-delete"></el-button>-->
                    <!--</el-popover>-->
                  <!--</span>-->
                <!--</el-tooltip>-->
              <!--</span>-->
                        <!--</template>-->
                    <!--</el-table-column>-->
                    <!--<el-table-column-->
                            <!--v-if="buttonType=='text'"-->
                            <!--label="操作">-->
                        <!--<template slot-scope="scope">-->
              <!--<span>-->

                  <!--&lt;!&ndash;<el-button size="mini"&ndash;&gt;-->
                  <!--&lt;!&ndash;@click="editButton(scope.row.id)"&ndash;&gt;-->
                  <!--&lt;!&ndash;v-if="userPermissions.indexOf('sys_user_manage_edit') != -1"&ndash;&gt;-->
                  <!--&lt;!&ndash;&gt;修改密码</el-button>&ndash;&gt;-->

                <!--<el-popover-->
                        <!--v-if="userPermissions.indexOf('sys_user_manage_delete') != -1"-->
                        <!--placement="top"-->
                        <!--width="150"-->
                        <!--v-model="scope.row.visible">-->
                  <!--<p>确定要删除该用户吗？</p>-->
                  <!--<div style="text-align: right; margin: 0;">-->
                    <!--<el-button type="text" size="mini" @click="scope.row.visible=false">取消</el-button>-->
                    <!--<el-button type="danger" size="mini" @click="deleteButton(scope.row.id)">确定</el-button>-->
                  <!--</div>-->
                  <!--<el-button slot="reference" type="danger" size="mini">删除</el-button>-->
                <!--</el-popover>-->
              <!--</span>-->
                        <!--</template>-->
                    <!--</el-table-column>-->
                </ApeTable>
            </div>
        </PageHeaderLayout>
        <ApeDrawer :drawerData="drawerData" @drawerClose="drawerClose" @drawerConfirm="drawerConfirm">
            <template slot="ape-drawer">
                <el-form :model="formData" :rules="rules" ref="CustomerForm" label-position="right"
                         label-width="96px">
                    <el-form-item label="账号" prop="account">
                        <el-input v-model="formData.account"></el-input>
                    </el-form-item>
                    <el-form-item label="密码" prop="password">
                        <el-input v-model="formData.password" type="password"></el-input>
                    </el-form-item>
                    <el-form-item label="状态" prop="enable">
                        <el-radio-group v-model="formData.enable">
                            <el-radio label="T" border>启用</el-radio>
                            <el-radio label="F" border>禁用</el-radio>
                        </el-radio-group>
                    </el-form-item>
                </el-form>
            </template>
        </ApeDrawer>

        <ApeDrawer :drawerData="changeDrawerData" @drawerClose="drawerChangeClose" @drawerConfirm="drawerChangeConfirm">
            <template slot="ape-drawer">
                <el-form :model="passwordChangeForm" :rules="pwdRules" ref="changePwd" label-position="right"
                         label-width="96px">
                    <el-form-item label="旧密码" prop="password">
                        <el-input v-model="passwordChangeForm.password" type="password"></el-input>
                    </el-form-item>
                    <el-form-item label="新密码" prop="new_pwd">
                        <el-input v-model="passwordChangeForm.new_pwd" type="password"></el-input>
                    </el-form-item>
                    <el-form-item label="确认密码" prop="confirm_pwd">
                        <el-input v-model="passwordChangeForm.confirm_pwd" type="password"></el-input>
                    </el-form-item>
                </el-form>
            </template>
        </ApeDrawer>
    </div>
</template>

<script>

    const DEL = "del";

    import PageHeaderLayout from '@/layouts/PageHeaderLayout'
    import ApeTable from '@/components/ApeTable'
    import ApeDrawer from '@/components/ApeDrawer'
    import ApeUploader from '@/components/ApeUploader';
    import Sortable from 'sortablejs'
    import {mapGetters} from 'vuex'

    export default {
        components: {
            PageHeaderLayout,
            ApeTable,
            ApeDrawer,
            ApeUploader
        },
        data() {
            return {
                changeDrawerData: {
                    visible: false,
                    loading: false,
                    loading_text: '玩命加载中……',
                    title: '',
                    width_height: '560px',
                },

                isChange: false,

                loadingStatus: true,
                columns: [
                    {
                        title: '名称',
                        value: 'username',
                    },
                    {
                        title: '状态',
                        value: 'enable',
                        value_display: "enable_alias",
                        style_plan: [
                            {
                                value: "T",
                                style: "color:#67C23A;"
                            },
                            {
                                value: "F",
                                style: "color:#F56C6C;"
                            }
                        ]
                    },
                ],
                // 表格列表数据
                customerList: [],
                // 分页信息
                pagingData: {
                    is_show: true,
                    layout: 'total, sizes, prev, pager, next, jumper',
                    total: 0,
                    offset: 0, // 分页的offset,序号列使用
                },
                // 表单结构
                formData: {
                    account: '',
                    password: '',
                    enable: 'T',
                },
                // 表单验证
                rules: {
                    account: [
                        {required: true, message: '请输入账号', trigger: 'blur'}
                    ],
                    password: [
                        {required: true, message: '请输入密码', trigger: 'blur'}
                    ]
                },

                passwordChangeForm: {
                    password: '',
                    new_pwd: '',
                    confirm_pwd: ''
                },

                // 抽屉数据
                drawerData: {
                    visible: false,
                    loading: true,
                    loading_text: '玩命加载中……',
                    title: '',
                    width_height: '560px',
                },

                //上传文件抽屉数
                drawerImportData: {
                    visible: false,
                    loading: true,
                    loading_text: '玩命加载中……',
                    title: '',
                    width_height: '560px',
                },
                typeList: [],
                //搜索表单
                searchForm: {},
                //自动填充数据
                restaurants: [],
                //导入题库表单数据
                importDrawerForm: {},

                //文件上传
                uploadFileList: [],

                //题库ID
                qbId: "",

                pwdRules: {
                    password: [
                        {required: true, min: 6, max: 16, message: '长度在6到16个字符', trigger: 'blur'}
                    ],
                    new_pwd: [
                        {required: true, message: '请输入密码', trigger: 'blur'},
                    ],
                    confirm_pwd: [
                        {required: true, validator: this.vaildateConfirmPass, trigger: 'blur'}
                    ]
                },

                userId: "",
            }
        },
        computed: {
            ...mapGetters(['userPermissions', 'buttonType'])
        },
        methods: {
            // 切换页码操作
            async switchPaging() {
                this.resetUserList()
            },

            //编辑按钮
            async editButton() {
                this.changeDrawerData.visible = true;
                this.changeDrawerData.title = '修改当前账户密码';
            },

            // 响应添加按钮
            async addButton() {
                this.drawerData.loading_text = '玩命加载中……';
                this.drawerData.visible = true;
                this.drawerData.title = '添加题库';
                this.typeList = [];//list
                this.uploadFileList = [];
                this.$nextTick(() => {
                    this.drawerData.loading = false
                })
            },

            // 处理抽屉关闭
            drawerClose() {
                this.resetFormData();
                this.drawerData.visible = false;
                this.drawerData.loading = true
            },

            // 处理抽屉确认
            async drawerConfirm() {
                // 调用组件的数据验证方法
                this.$refs['CustomerForm'].validate((valid) => {
                    if (valid) {
                        this.formSubmit()
                    } else {
                        this.$message.error('数据验证失败，请检查必填项数据！')
                    }
                })
            },

            // form数据提交，请求接口
            async formSubmit() {
                this.drawerData.loading_text = '玩命提交中……';
                this.drawerData.loading = true;
                let params = {
                    account: this.formData.account,
                    password: this.formData.password,
                    enable: this.formData.enable
                };

                let id = await this.$api.createUser(params);
                this.$nextTick(() => {
                    this.drawerData.visible = false;
                    if (id) {
                        this.resetUserList()
                    }
                });
                this.$nextTick(() => {
                    this.$message.success('保存成功!');
                    this.resetUserList();
                });
                this.resetFormData()
            },

            // 相应删除按钮
            async deleteButton(id) {
                let info = await this.$api.deleteMannerUser({id});
                if (info == 'ok') {
                    this.$nextTick(() => {
                        this.$message.success("删除成功");
                        this.resetUserList(DEL)
                    })
                } else {
                    this.$message.error(info)
                }

            },

            // 初始化数据
            resetFormData() {
                // 初始化form表单
                this.formData = {
                    account: '',
                    password: '',
                    enable: 'T'
                };

                this.$nextTick(() => {
                    this.$refs['CustomerForm'].resetFields();
                })
            },

            // 初始化客服列表
            async resetUserList(type, params = {}) {
                this.loadingStatus = true;
                let inputData = this.$refs['apeTable'].getPagingInfo(type);
                for (let param in params) {
                    inputData[param] = params[param];
                }
                let data = await this.$api.getPassportUserList(inputData);
                this.customerList = [];
                this.$nextTick(() => {
                    this.customerList = data
                });

                this.loadingStatus = false;
            },

            // 拖拽排序数据提交，请求接口
            async dragSortSubmit() {
                // let info = await this.$api.orderCarousel(orderIds)
                // if (info == 'ok') {
                //     this.$nextTick(() => {
                //         this.initCarouselList()
                //     })
                // }
                this.$message.success('保存成功!')
            },

            // 表格拖拽排序，同层级移动有效果
            dragSort() {
                const el = document.querySelectorAll('.el-table__body-wrapper > table > tbody')[0];
                this.sortable = Sortable.create(el, {
                    handle: ".drag-handle",
                    setData: function (dataTransfer) {
                        dataTransfer.setData('Text', '')
                    },
                    onEnd: () => {
                        let Ids = [];
                        let tmp = el.querySelectorAll('.drag-handle');
                        for (let i = 0, len = tmp.length; i < len; i++) {
                            Ids.push(tmp[i].dataset.id)
                        }
                        this.dragSortSubmit(Ids)
                    },
                })
            },

            //搜索
            onSearchClick() {
                this.$refs['apeTable'].resetCurPageSize();
                this.resetUserList(DEL, this.searchForm);
            },

            //获取题库所有名称
            async getAllQuestionBankName() {
                let inputData = {
                    page_size: 1000,
                    current_page: 1
                };
                let {list, pages} = await this.$api.getQuestionBankList(inputData);

                this.$nextTick(() => {
                    if (list.length < 0) {
                        return;
                    }

                    for (let item of list) {
                        let itemObjt = {
                            value: item.name,
                            id: item.id
                        };

                        this.restaurants.push(itemObjt);
                    }

                });
            },

            //搜索重置
            onResetSearch() {
              this.searchForm = {}
                this.resetUserList();
            },


            //修改密码
            drawerChangeConfirm() {
                let password = this.passwordChangeForm.password;
                let newPassword = this.passwordChangeForm.new_pwd;
                let confirmPwd = this.passwordChangeForm.confirm_pwd;
                this.$refs['changePwd'].validate(async (valid) => {
                    if (valid) {
                        let params = {
                            new_password: newPassword,
                            password: password
                        };
                        let info =  await this.$api.updateUserPwd(params);

                        if (info) {
                            this.$message.success('修改成功');
                            this.changeDrawerData.visible = false;

                            this.passwordChangeForm = {
                                password: '',
                                new_pwd: '',
                                confirm_pwd: ''
                            };

                            this.$api.submitLogout();
                            localStorage.removeItem('user_info');
                            this.$message.success('退出成功！');
                            this.$router.push('/login')
                        }

                    } else {
                        this.$message.error('请填写必填项');
                    }
                });
            },

            //取消弹框
            drawerChangeClose() {
                this.changeDrawerData.visible = false;

                this.passwordChangeForm = {
                    password: '',
                    new_pwd: '',
                    confirm_pwd: ''
                };
            },


            vaildateConfirmPass(rule, value, callback) {

                if (value === '') {
                    callback(new Error('请再次输入密码'))
                } else if (value != this.passwordChangeForm.new_pwd) {
                    callback(new Error('两次输入密码不一致!'))
                } else {
                    callback()
                }
            }


        },
        mounted() {
            this.resetUserList();
            this.$nextTick(() => {
                this.dragSort()
            })
        },
    }
</script>

<style lang="stylus" scoped>
    .table-header
        margin-bottom 12px

    .drag-handle
        font-size 24px
        cursor pointer

    .el-input-group__prepend, .el-input-group__append
        background #ffffff
        padding 0 12px

    .el-input-group__append
        color #ffffff
        background #1890ff

    .el-popover .el-checkbox-group .el-checkbox
        margin-left 0
        margin-right 12px

    /*.el-select > .el-input*/
    /*width 373.33px*/

    .el-radio.is-bordered
        width 100px

    .el-color-picker
        position absolute
</style>
