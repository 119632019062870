<template>
  <div>
    <PageHeaderLayout>
      <div class="main-page-content">
        <!--<el-row class="table-header">-->
        <!--<el-button type="primary" size="medium"-->
        <!--v-if="userPermissions.indexOf('user_import') != -1 "-->
        <!--@click="importButton()">导入-->
        <!--</el-button>-->
        <!--</el-row>-->
        <el-row v-if="summary" class="table-search">
          <el-form  :inline="true" class="demo-form-inline">
            <el-form-item label="普通用户数：">
              <span>{{summary.total_user_count - summary.total_vip_count}}</span>
            </el-form-item>
            <el-form-item label="有效会员数：">
              <span>{{summary.total_vip_count}}</span>
            </el-form-item>
          </el-form>
        </el-row>

        <el-row class="table-search">
          <el-form :inline="true" :model="searchForm" class="demo-form-inline">
            <el-form-item label="手机号码">
              <el-input placeholder="请输入手机号码" v-model="searchForm.phone" clearable :maxLength="11"/>
            </el-form-item>
            <el-form-item label="用户昵称">
              <el-input placeholder="请输入用户昵称" v-model="searchForm.nick_name" clearable/>
            </el-form-item>
            <el-form-item label="用户注册时间">
              <el-date-picker
                  v-model="searchForm.timeValue"
                  type="daterange"
                  align="right"
                  unlink-panels
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  value-format="yyyy-MM-dd"
              @change="onSearchDateChange">
              </el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="onSearchClick" style="margin-left: 12px">搜索</el-button>
            </el-form-item>
            <el-form-item>
              <el-button @click="onResetSearch">重置</el-button>
            </el-form-item>
          </el-form>
        </el-row>

        <ApeTable ref="apeTable" :data="userList" :columns="columns" :loading="loadingStatus"
                  :pagingData="pagingData" @switchPaging="switchPaging" highlight-current-row>
          <el-table-column
              slot="first-column"
              width="80"
              align="center"
              label="Drag">
            <template slot-scope="scope">
              <el-tooltip effect="dark" content="拖动排序" placement="top-start">
                <span class="drag-handle" :data-id="scope.row.id"><i class="el-icon-rank"></i></span>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
              v-if="buttonType=='icon'"
              label="操作"
              width="150"
              fixed="right"
          >
            <template slot-scope="scope">
              <span>
                <el-tooltip effect="dark" content="查看" placement="top-start"
                            v-if="userPermissions.indexOf('user_view') != -1">
                  <el-button size="mini" icon="el-icon-view" @click="viewButton(scope.row.id)"></el-button>
                </el-tooltip>

                  <el-tooltip effect="dark" content="升级为VIP用户" placement="top-start"
                              v-if="userPermissions.indexOf('user_up') != -1&&!scope.row.is_vip">
                  <el-button size="mini" type="primary" icon="el-icon-medal"
                             @click="userUpButton(scope.row)"></el-button>
                </el-tooltip>

                  <el-tooltip effect="dark" content="查看VIP信息" placement="top-start"
                              v-if="userPermissions.indexOf('user_up') != -1&&scope.row.is_vip">
                  <el-button size="mini" type="success" icon="el-icon-medal"
                             @click="userUpButton(scope.row)"></el-button>
                </el-tooltip>

              </span>
            </template>
          </el-table-column>

          <el-table-column
              v-if="buttonType=='text'"
              label="操作"
              width="150"
              fixed="right"
          >
            <template slot-scope="scope">
                            <span>
                  <el-button size="mini"
                             v-if="userPermissions.indexOf('user_view') != -1"
                             @click="viewButton(scope.row.id)">查看</el-button>

                                  <el-tooltip effect="dark"
                                              content="升级为VIP用户"
                                              placement="top-start"
                                              v-if="userPermissions.indexOf('user_up') != -1&&!scope.row.is_vip">
                  <el-button size="mini"
                             type="warning"
                             @click="userUpButton(scope.row)">升级VIP
                  </el-button>
                </el-tooltip>
                                <el-tooltip effect="dark"
                                            content="查看VIP信息"
                                            placement="top-start"
                                            v-if="userPermissions.indexOf('user_up') != -1&&scope.row.is_vip">
                  <el-button size="mini"
                             type="success"
                             @click="userUpButton(scope.row)">VIP信息
                  </el-button>
                </el-tooltip>
                           </span>
            </template>
          </el-table-column>

        </ApeTable>
      </div>
    </PageHeaderLayout>
    <ApeDrawer :drawerData="drawerData" @drawerClose="drawerClose" @drawerConfirm="drawerConfirm">
      <template slot="ape-drawer">
        <!--<el-alert-->
        <!--:title="message"-->
        <!--type="warning"-->
        <!--v-if="formData.is_vip && isVip"-->
        <!--:closable="false"-->
        <!--show-icon-->
        <!--style="margin-top: -10px"-->
        <!--&gt;-->
        <!--</el-alert>-->
        <el-form :model="formData" label-position="right" label-width="96px">
          <el-form-item label="用户头像：">
            <div class="avatar-box">
              <img :src="formData.avatar_url" width="44px"/>
            </div>
          </el-form-item>
          <el-form-item label="用户ID：">
            <el-input v-model="formData.id" disabled></el-input>
          </el-form-item>
          <el-form-item label="用户昵称：">
            <el-input v-model="formData.nick_name" disabled></el-input>
          </el-form-item>
          <el-form-item label="用户等级">
            <el-input v-model="formData.is_vip?'VIP用户':'普通用户'" disabled></el-input>
          </el-form-item>
          <el-form-item label="会员到期时间" v-if="formData.is_vip">
            <!--<el-input v-model="formData.died_at"></el-input>-->
            <el-date-picker
                v-model="formData.died_at"
                value-format="yyyy-MM-dd HH:mm:ss"
                type="datetime"
                placeholder="选择日期时间"
                :disabled="isShow"
                :picker-options="limitData"
            >
            </el-date-picker>
            <span style="color: #999;margin-left: 10px" v-if="!isShow">(可重新设置会员到期时间)</span>
          </el-form-item>
          <el-form-item label="openid：" v-if="!isVip">
            <el-input v-model="formData.openid" disabled></el-input>
          </el-form-item>
          <el-form-item label="状态：">
            <el-input v-model="formData.enabled_alias" disabled></el-input>
          </el-form-item>
          <el-form-item label="注册时间：">
            <el-input v-model="formData.reg_time" disabled></el-input>
          </el-form-item>
          <el-form-item label="创建时间：">
            <el-input v-model="formData.created_at" disabled></el-input>
          </el-form-item>
        </el-form>
        <el-col v-if="!isVip" style="margin-top: 12px">
          <el-col :span="4" style="margin-top: 5px">
            <div style="margin-left: 15px;margin-top: 10px">选择套餐：</div>
          </el-col>
          <el-col :span="19" style="margin-left: 10px">
            <el-radio-group v-model="orderId">
              <el-radio v-for="(item,index) in orderList"
                        :key="index"
                        :label="item.id"
                        :class="[{ 'border-no-pick': orderId !== item.id, 'border-pick':orderId === item.id}, 'permissions-checked']"
              >
                {{ item.label }}
              </el-radio>
            </el-radio-group>
          </el-col>
        </el-col>
      </template>
    </ApeDrawer>
    <el-dialog :visible.sync="isShowTitle" title="提示" width="30%" :destroy-on-close="true">
      <span>该用户已经是会员,再次升级默认延长用户的会员时长！</span>
      <div slot="footer" class="dialog-footer">
        <el-button @click="onDialogCancel">关闭</el-button>
        <el-button type="primary" @click="onDialogConfirm">确定</el-button>
      </div>
    </el-dialog>

    <ApeDrawer :drawerData="drawerImportData" @drawerClose="importDrawerClose" @drawerConfirm="importDrawerConfirm">
      <template slot="ape-drawer">
        <el-form :model="importDrawerForm" label-width="96px">
          <el-form-item label="上传文件">
            <uploader-file :limit="1" @handleUploadSuccess="handleUploadSuccess"
                           @handleUploadRemove="handleUploadRemove"
                           :upload-file-list="uploadFileList"
            ></uploader-file>
          </el-form-item>
        </el-form>
      </template>
    </ApeDrawer>
  </div>
</template>

<script>
const DEL = "del";
const NO_DEL = "no_del";

import PageHeaderLayout from '@/layouts/PageHeaderLayout'
import ApeTable from '@/components/ApeTable'
import ApeDrawer from '@/components/ApeDrawer'
import UploaderFile from '@/components/UploaderFile'
import Sortable from 'sortablejs'
import {mapGetters} from 'vuex'

export default {
  components: {
    PageHeaderLayout,
    ApeTable,
    ApeDrawer,
    UploaderFile
  },
  data() {
    return {
      loadingStatus: false,
      columns: [
        {
          title: '用户昵称',
          value: 'nick_name',
        },
        {
          title: '手机号',
          value: 'phone',
        },
        {
          title: '用户头像',
          type: 'image',
          value: 'avatar_url'
        },
        {
          title: '级别',
          value: 'is_vip',
          value_display: 'level',
          class_plan: [
            {
              value: false,
              class: ["el-tag", "el-tag--warning"]
            },
            {
              value: true,
              class: "el-tag el-tag--success"
            }
          ]
        },
        {
          title: '状态',
          value: 'enabled',
          value_display: "enabled_alias",
          style_plan: [
            {
              value: "T",
              style: "color:#67C23A;"
            },
            {
              value: "F",
              style: "color:#F56C6C;"
            }
          ]
        },
        {
          title: '注册时间',
          value: 'reg_time',
        },
      ],
      // 表格列表数据
      userList: [],
      // 分页信息
      pagingData: {
        is_show: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        offset: 0, // 分页的offset,序号列使用
      },
      // 表单结构
      formData: {
        died_at: ''
      },
      // 抽屉数据
      drawerData: {
        visible: false,
        loading: true,
        loading_text: '玩命加载中……',
        title: '',
        width_height: '560px',
      },
      typeList: [],
      //搜索表单
      searchForm: {},
      dateForm: {
        isShowData: true,
        label: '用户注册时间'
      },
      orderList: [],
      isVip: false,
      isShowTitle: false,
      message: '该用户已经是会员，再次升级会延长用户的会员时长',
      orderId: 1,

      //上传文件抽屉数
      drawerImportData: {
        visible: false,
        loading: false,
        loading_text: '玩命加载中……',
        title: '',
        width_height: '560px',
      },

      //导入手机号数据
      importDrawerForm: {},
      uploadFileList: [],

      isShow: false,

      //限制日期
      limitData: {
        disabledDate(time) {
          return time.getTime() <= Date.now() - 8.64e6
        }
      },

      searchParams: {},
      summary: {}
    }
  },
  computed: {
    ...mapGetters(['userPermissions', 'buttonType'])
  },
  methods: {
    // 切换页码操作
    async switchPaging() {
      let params = {}
      params.start_time = this.searchForm.start_time
      params.end_time = this.searchForm.end_time
      params.phone = this.searchForm.phone
      params.nick_name = this.searchForm.nick_name
      await this.iniUserList(DEL, params);
    },

    //响应导入按钮
    async importButton() {
      this.drawerImportData.visible = true;
      this.drawerImportData.title = '导入手机号';
      this.$nextTick(() => {
        this.drawerImportData.loading = false
      })
    },

    // 响应查看按钮
    async viewButton(id) {
      this.typeList = [];
      this.isVip = true;
      this.isShow = true;
      this.drawerData.visible = true;
      this.drawerData.loading_text = '玩命加载中……';
      this.drawerData.title = '用户信息(ID：' + id + ')';

      let {info} = await this.$api.getUserInfo(id);
      this.formData = info;
      this.drawerData.loading = false
    },

    async userUpButton(rowData) {
      let id = rowData.id;
      let isVip = rowData.is_vip;
      if (!id) {
        this.$message('该用户不存在');
        return;
      }

      if (isVip) {
        this.drawerData.title = '查看VIP信息(ID：' + id + ')';
        this.isShow = false
      } else {
        this.drawerData.title = '升级VIP(ID：' + id + ')';
        this.isShow = false
      }

      this.drawerData.loading_text = '玩命加载中……';
      this.typeList = [];
      let {info} = await this.$api.getUserInfo(id);
      this.formData = info;
      this.isVip = info.is_vip;

      this.drawerData.visible = true;

      this.drawerData.loading = false
    },

    //获取套餐详情
    async getOrderMenu() {
      // this.loadingStatus = true;

      let inputData = {
        page_size: 10,
        current_page: 1
      };

      let {list, pages} = await this.$api.getPackageList(inputData);

      this.$nextTick(() => {
        for (let order of list) {
          let object = {
            id: order.id,
            label: order.name
          };

          this.orderList.push(object);
        }

      });
      // this.loadingStatus = false
    },

    // 处理抽屉关闭
    drawerClose() {
      this.drawerData.visible = false;
      this.drawerData.loading = true;
      this.isVip = false;
    },

    // 处理抽屉确认
    async drawerConfirm() {
      // 调用组件的数据验证方法
      if (this.isVip) {
        // this.addUserVip();
        this.modifyUserVipDiedAt();
      } else {
        // this.$refs['questionBankForm'].validate((valid) => {
        //     if (valid) {
        //         this.formSubmit()
        //     } else {
        //         this.$message.error('数据验证失败，请检查必填项数据！')
        //     }
        // })
        this.addUserVip();
      }
    },

    //修改会员到期时间
    async modifyUserVipDiedAt() {
      let param = {
        user_id: this.formData.id,
        died_at: this.formData.died_at
      };
      let info = await this.$api.modifyUserVip(param);
      if (info) {
        this.$message.success("修改会员到期时间成功");
        this.drawerData.visible = false;

        let obj = this.getSearchInfo()
        await this.iniUserList(NO_DEL, obj);
      } else {
        this.$message.error("修改会员到期时间失败");
      }
    },

    async addUserVip() {
      this.drawerData.loading = true;
      let data = {
        package_id: this.orderId,
        user_id: this.formData.id,
      };

      let info = await this.$api.setUserVip(data);
      this.drawerData.loading = false;
      if (info) {
        this.$message.success("添加会员成功");
        this.drawerData.visible = false;

        let obj = this.getSearchInfo()
        await this.iniUserList(NO_DEL, obj);

      } else {
        this.$message.success("添加会员失败");
      }

    },

    async formSubmit() {
      this.isVip = false;
    },

    // 初始用户列表
    async iniUserList(type, params = {}) {
      this.loadingStatus = true;
      let inputData = this.$refs['apeTable'].getPagingInfo(type);

      for (let param in params) {
        if (params[param] === "") {
          continue;
        }
        inputData[param] =params[param];
      }

      let {list, pages, summary} = await this.$api.getUserList(inputData);
      this.userList = [];
      this.$nextTick(() => {
        this.userList = list
      });
      this.pagingData.total = pages.total;
      this.pagingData.offset = pages.offset;
      this.loadingStatus = false

      this.summary = summary
    },

    // 拖拽排序数据提交，请求接口
    async dragSortSubmit() {
      // let info = await this.$api.orderCarousel(orderIds)
      // if (info == 'ok') {
      //     this.$nextTick(() => {
      //         this.initCarouselList()
      //     })
      // }
      this.$message.success('保存成功!')
    },

    // 表格拖拽排序，同层级移动有效果
    dragSort() {
      const el = document.querySelectorAll('.el-table__body-wrapper > table > tbody')[0]
      this.sortable = Sortable.create(el, {
        handle: ".drag-handle",
        setData: function (dataTransfer) {
          dataTransfer.setData('Text', '')
        },
        onEnd: () => {
          let Ids = [];
          let tmp = el.querySelectorAll('.drag-handle');
          for (let i = 0, len = tmp.length; i < len; i++) {
            Ids.push(tmp[i].dataset.id)
          }
          this.dragSortSubmit(Ids)
        },
      })
    },

    //搜索
    async onSearchClick() {
      this.$refs['apeTable'].resetCurPageSize();
      // if (this.searchForm.timeValue && this.searchForm.timeValue.length > 0) {
      //   let timeVal = this.searchForm.timeValue
      //   let startTime = timeVal[0]
      //   let endTime = timeVal[1]
      //   this.$set(this.searchForm, "start_time", startTime)
      //   this.$set(this.searchForm, "end_time", endTime)
      // }
      // let params = {}
      // params.start_time = this.searchForm.start_time
      // params.end_time = this.searchForm.end_time
      // params.phone = this.searchForm.phone
      // params.nick_name = this.searchForm.nick_name
      let params = this.getSearchInfo()
      await this.iniUserList(DEL, params);
    },

    getSearchInfo() {
      // console.log(this.searchForm, 'searchForm')
      if (JSON.stringify(this.searchForm) === '{}') {
        return {}
      }

      if (this.searchForm.timeValue && this.searchForm.timeValue.length > 0) {
        let timeVal = this.searchForm.timeValue
        let startTime = timeVal[0]
        let endTime = timeVal[1]
        this.$set(this.searchForm, "start_time", startTime)
        this.$set(this.searchForm, "end_time", endTime)
      }
      let params = {}
      params.start_time = this.searchForm.start_time
      params.end_time = this.searchForm.end_time
      params.phone = this.searchForm.phone
      params.nick_name = this.searchForm.nick_name
      return params
    },

    //搜索重置
    onResetSearch() {
      this.searchForm = {};
      this.iniUserList();
    },

    onSearchDateChange(e) {
      if (!e) {
        this.$set(this.searchForm, "start_time", '')
        this.$set(this.searchForm, "end_time", '')
      }
    },

    //取消弹框
    onDialogCancel() {
      this.isShowTitle = false;
    },

    //确定
    onDialogConfirm() {
      this.isShowTitle = false;
      this.drawerData.visible = true;
    },

    //导入文件抽屉确定
    async importDrawerConfirm() {
      // this.resetFormData();
      this.drawerImportData.visible = true;
      this.drawerImportData.loading = true;


      //todo 手机Excel上传
      // let qbId = this.questionBankInfo.id;
      //
      // if (!qbId) {
      //     let name = window.location.origin +'/'+ 'qb_info';
      //     let qbInfo = JSON.parse(localStorage.getItem(name));
      //     qbId = qbInfo.id;
      // }


      // let data = {
      //     file:this.importDrawerForm.file_id,
      //     extra_params:{
      //         'qb_id':qbId,
      //         'in_question_type':false,
      //         'in_chapter':false,
      //         'in_real':true
      //     }
      // };


      // let info = await this.$api.storeImportQuestion(data);
      //
      // if(info == "ok"){
      //     this.$message.success("上传成功");
      //     this.$router.push('/import_file_list');
      // }else {
      //     this.$message.error("上传失败");
      // }

      this.drawerImportData.loading = false;
    },

    //导入文件抽屉取消
    importDrawerClose() {
      // this.resetFormData();
      this.importDrawerForm = {};
      this.uploadFileList = [];
      this.drawerImportData.visible = false;
      this.drawerImportData.loading = true
    },

    // 文件上传成功回调
    handleUploadSuccess(file, fileList) {
      this.importDrawerForm.file_id = file.id;
    },
    // 文件删除回调
    handleUploadRemove(file, fileList) {
      this.importDrawerForm.file_id = 0;
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.iniUserList();
      this.getOrderMenu();
      this.dragSort()
    })
  },
}
</script>

<style lang="stylus" scoped>
.table-header
  margin-bottom 12px

.drag-handle
  font-size 24px
  cursor pointer

.el-input-group__prepend, .el-input-group__append
  background #ffffff
  padding 0 12px

.el-input-group__append
  color #ffffff
  background #1890ff

.el-popover .el-checkbox-group .el-checkbox
  margin-left 0px
  margin-right 12px

.el-select > .el-input
  width 373.33px

.el-radio.is-bordered
  width 100px

.el-color-picker
  position absolute

.avatar-box
  width 44px
  height 44px
  overflow hidden
  border-radius 50%

.permissions-checked
  display inline-block;
  height 40px;
  margin-right 10px
  line-height 40px;
  padding 0 12px 0 12px
  box-sizing border-box
  margin-top 10px
  border-radius 5px
  font-size 14px
  overflow hidden

.border-no-pick
  border 1px solid #cbcdcf

.border-pick
  border 1px solid #1890ff
</style>
