<template>
    
</template>

<script>
    const WINDOW_LOCAL_ORIGIN = process.env.VUE_APP_POROXY_TARGET;
    export default {
        name: "GetQuestionBank",
        data(){
            return{
                qbInfo:{}
            }
        },

        mounted(){
                this.getQbInfo();
        },

        methods:{
            getQbInfo(){
                let userInfo = JSON.parse(localStorage.getItem('user_info'));
                let name = WINDOW_LOCAL_ORIGIN + '_' + userInfo.id +'_'+'qb_info';
                let qbInfo = JSON.parse(localStorage.getItem(name));

                if(qbInfo && JSON.stringify(qbInfo) !== '{}'){
                    this.qbInfo = qbInfo;
                }
            }
        },
    }
</script>

<style scoped>

</style>