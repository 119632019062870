<template>
  <div>
    <router-view v-show="$route.matched.length==3"></router-view>
    <PageHeaderLayout v-show="$route.matched.length==2">
      <div class="main-page-content">
        <el-row class="table-header" type="flex" justify="space-between">
          <el-col :span="18">
            <el-button type="primary" size="medium"
                       v-if="userPermissions.indexOf('test_paper_create') != -1 "
                       @click="addButton()">添加
            </el-button>
          </el-col>
          <!--                    <el-col :span="5" :offset="1">-->
          <!--                        <change-question-bank @confirm="onChangeQuestionBankConfirm"></change-question-bank>-->
          <!--                    </el-col>-->
        </el-row>

        <el-row class="table-search">
          <el-form :inline="true" :model="searchForm" class="demo-form-inline">
            <el-form-item label="模拟试卷名称">
              <el-input placeholder="请输入模拟试卷名称" clearable v-model="searchForm.name"/>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="onSearchClick" style="margin-left: 12px">搜索</el-button>
            </el-form-item>
            <el-form-item>
              <el-button @click="onResetSearch">重置</el-button>
            </el-form-item>
          </el-form>
        </el-row>

        <ApeTable ref="apeTable" :data="questionBankList" :columns="columns" :loading="loadingStatus"
                  :pagingData="pagingData" @switchPaging="switchPaging" highlight-current-row>
          <el-table-column
              slot="first-column"
              width="80"
              align="center"
              label="Drag">
            <template slot-scope="scope">
              <el-tooltip effect="dark" content="拖动排序" placement="top-start">
                <span class="drag-handle" :data-id="scope.row.id"><i class="el-icon-rank"></i></span>
              </el-tooltip>
            </template>
          </el-table-column>

          <el-table-column
              v-if="buttonType=='icon'"
              label="操作"
              width="200"
              fixed="right"
          >
            <template slot-scope="scope">
              <span>
                     <el-tooltip effect="dark" content="选题" placement="top-start"
                                 v-if="userPermissions.indexOf('test_paper_add') != -1">
                  <el-button size="mini" type="primary" icon="el-icon-plus"
                             @click="addQuestionButton(scope.row.id)"></el-button>
                </el-tooltip>
                <el-tooltip effect="dark" content="编辑" placement="top-start"
                            v-if="userPermissions.indexOf('test_paper_edit') != -1">
                  <el-button size="mini" icon="el-icon-edit" @click="editButton(scope.row.id)"></el-button>
                </el-tooltip>
                  <el-tooltip effect="dark" content="删除" placement="top-start"
                              v-if="userPermissions.indexOf('test_paper_delete') != -1">
                  <el-button size="mini" type="danger" icon="el-icon-delete"
                             @click="deleteButton(scope.row.id)"></el-button>
                </el-tooltip>
                <!--<el-tooltip effect="dark" content="删除" placement="top-start">-->
                <!--<span>-->
                <!--<el-popover-->
                <!--v-if="userPermissions.indexOf('test_test_paper_delete') != -1"-->
                <!--placement="top"-->
                <!--width="150"-->
                <!--v-model="scope.row.visible">-->
                <!--<p>确定要删除记录吗？</p>-->
                <!--<div style="text-align: right; margin: 0;">-->
                <!--<el-button type="text" size="mini" @click="scope.row.visible=false">取消</el-button>-->
                <!--<el-button type="danger" size="mini" @click="deleteButton(scope.row.id)">确定</el-button>-->
                <!--</div>-->
                <!--<el-button slot="reference" type="danger" size="mini" icon="el-icon-delete"></el-button>-->
                <!--</el-popover>-->
                <!--</span>-->
                <!--</el-tooltip>-->
              </span>
            </template>
          </el-table-column>
          <el-table-column
              v-if="buttonType=='text'"
              label="操作"
              width="200"
              fixed="right"
          >
            <template slot-scope="scope">
              <div>

                <el-button size="mini" type="primary"
                           v-if="userPermissions.indexOf('test_paper_add') != -1"
                           @click="addQuestionButton(scope.row.id)">选题
                </el-button>

                <el-button size="mini" v-if="userPermissions.indexOf('test_paper_edit') != -1"
                           @click="editButton(scope.row.id)">编辑
                </el-button>

                <el-button size="mini"
                           type="danger"
                           v-if="userPermissions.indexOf('test_paper_delete') != -1"
                           @click="deleteButton(scope.row.id)">删除
                </el-button>

              </div>
            </template>
          </el-table-column>
        </ApeTable>
      </div>
    </PageHeaderLayout>
    <ApeDrawer :drawerData="drawerData" @drawerClose="drawerClose" @drawerConfirm="drawerConfirm">
      <template slot="ape-drawer">
        <el-col class="content-left">
          <el-form ref="TestPaperForm" :model="formData" :rules="rules" label-width="80px">
            <el-form-item label="当前题库">
              <el-input v-model="curChooseBankName" disabled></el-input>
            </el-form-item>
            <el-form-item label="名称" prop="name">
              <el-input v-model="formData.name" placeholder="请输入模拟试卷名称"></el-input>
            </el-form-item>
            <el-form-item label="描述">
              <el-input v-model="formData.description" placeholder="请输入试卷描述"></el-input>
            </el-form-item>
            <el-form-item label="及格分" prop="pass_score">
              <el-col>
                <el-col :span="19">
                  <el-input v-model="formData.pass_score" onkeyup="value=value.replace(/[^\d]/g,'')"
                            placeholder="请输入及格分"></el-input>
                </el-col>
                <el-col :span="4" :offset="1">
                  <label>分</label>
                </el-col>
              </el-col>
            </el-form-item>
            <el-form-item label="考试时长" prop="exam_time">
              <el-col>
                <el-col :span="19">
                  <el-input v-model="formData.exam_time" onkeyup="value=value.replace(/[^\d]/g,'')"
                            placeholder="请输入考试时长"></el-input>
                </el-col>
                <el-col :span="4" :offset="1">
                  <label>分钟</label>
                </el-col>
              </el-col>
            </el-form-item>
            <el-form-item label="难度" prop="difficulty">
              <el-select
                  v-model="formData.difficulty"
                  filterable
                  clearable
                  placeholder="请选择难度"
              >
                <el-option
                    v-for="item in difficultList"
                    :key="item.id"
                    :label="item.title"
                    :value="item.value"
                ></el-option>
              </el-select>

            </el-form-item>
            <el-form-item label="状态" prop="status">
              <el-radio-group v-model="formData.status">
                <el-radio label="0" border>启用</el-radio>
                <el-radio label="-1" border>禁用</el-radio>
              </el-radio-group>
            </el-form-item>

            <div class="set-question-box" v-if="formData.strategy&& JSON.stringify(formData.strategy)!=='{}'">

              <el-row style="color: #606266">
                <el-col style="margin-bottom: 15px">题型设置<label
                    class="font-color-grey">(题目将从当前题库中随机抽取)</label>：
                </el-col>
                <div style="margin-top: 10px;margin-left: 10px">
                  总题目数：
                  <label class="font-color-blue">{{ questionCount }}</label>
                  题
                  <label class="font-color-grey">(该项自动计入，无需填写)</label>
                </div>
                <div style="margin-bottom: 10px;margin-left: 24px">
                  总分数：
                  <label class="font-color-blue">{{ questionAllScore }}</label>
                  分
                  <label class="font-color-grey">(该项自动计入，无需填写)</label>
                </div>

                <div style="padding: 30px">
                  <div v-for="(item, key, index) in formData.strategy" :key="key" class="x-row"
                       style="margin-bottom: 12px">
                    <div class="x-row">
                      <template>
                        <span style="padding-right: 12px;width: 180px">{{ item.question_name }}(共{{ setQuestionTypeCount(key)
                          }}题)</span>
                      </template>

                    </div>
                    <div class="x-row">
                      <div>
                        <span>抽取</span>
                        <el-input style="width: 90px;padding: 0 6px;text-align: center"
                                  v-model="item.count"
                                  :maxLength="3"
                                  :disabled="isReadOnly"
                                  onkeyup="value=value.replace(/\D/g,'')"
                                  @input="onQuestionInputCount(key, setQuestionTypeCount(key), $event)"
                        ></el-input>
                      </div>
                      <span>题</span>
                    </div>
                    <div class="x-row" style="margin-left: 60px;">
                      <span>每题</span>
                      <el-input style="width: 90px;padding: 0 6px;text-align: center"
                                v-model="item.each_score"
                                :disabled="isReadOnly"
                                onkeyup="value=value.replace(/\D/g,'')"
                                :maxLength="2"
                      ></el-input>
                      <span>分</span>
                    </div>
                    <div class="x-row" style="margin-left: 60px">
                      <el-button type="text" @click="resetQuestionTypeCount(key)">清空
                      </el-button>
                    </div>
                  </div>
                </div>

                <!--                                <el-col :offset="1" :span="21" v-for="(item,key,index) in formData.strategy" :key="key">-->
                <!--                                    <el-col align="middle" style="margin-bottom: 10px">-->
                <!--                                        <el-col :span="6" class="text-pos-right" style="margin-top: 2px">-->
                <!--                                            <el-input placeholder="请设置题目数量"-->
                <!--                                                      size="small"-->
                <!--                                                      disabled-->
                <!--                                                      style="text-align: right"-->
                <!--                                                      v-model="item.question_name"-->
                <!--                                            />-->
                <!--                                        </el-col>-->
                <!--                                        <el-col :span="7" :offset="1" style="margin-top: 2px">-->
                <!--                                            <el-input placeholder="请设置题目数量"-->
                <!--                                                      size="small"-->
                <!--                                                      maxlength="3"-->
                <!--                                                      :disabled="isReadOnly"-->
                <!--                                                      onkeyup="value=value.replace(/\D/g,'')"-->
                <!--                                                      v-model.number="item.count"-->
                <!--                                            />-->
                <!--                                        </el-col>-->
                <!--                                        <el-col :span="1" style="margin-top: 6px">-->
                <!--                                            题-->
                <!--                                        </el-col>-->

                <!--                                        <el-col :span="6" :offset="1" style="margin-top: 5px">-->
                <!--                                            <el-col :span="7" style="margin-top: 2px">每题</el-col>-->
                <!--                                            <el-col :span="7">-->
                <!--                                                <input class="input-box"-->
                <!--                                                       :disabled="isReadOnly"-->
                <!--                                                       v-model.number="item.each_score"-->
                <!--                                                       maxlength="3"-->
                <!--                                                       onkeyup="value=value.replace(/\D/g,'')"/>-->
                <!--                                            </el-col>-->
                <!--                                            <el-col :span="7" style="margin-top: 2px">分</el-col>-->
                <!--                                        </el-col>-->

                <!--                                    </el-col>-->
                <!--                                </el-col>-->
              </el-row>

            </div>

          </el-form>
        </el-col>
      </template>
    </ApeDrawer>
  </div>
</template>

<script>
const WINDOW_LOCAL_ORIGIN = process.env.VUE_APP_POROXY_TARGET;
const DEL = "del";
const NO_DEL = "no_del";
import PageHeaderLayout from '@/layouts/PageHeaderLayout';
import ApeTable from '@/components/ApeTable';
import ApeDrawer from '@/components/ApeDrawer';
import GetQuestionBankMixins from '../mixins/GetQuestionBankMixins'
import {mapGetters} from 'vuex';
import Sortable from 'sortablejs'

export default {
  components: {
    PageHeaderLayout,
    ApeTable,
    ApeDrawer,
  },
  mixins: [GetQuestionBankMixins],

  data() {
    return {
      loadingStatus: false,
      columns: [
        {
          title: '名称',
          value: 'name',
          width: '250'
        },
        {
          title: '描述',
          value: 'description',
        },
        {
          title: '难度',
          value: "difficulty_alias",
        },
        {
          title: '总分',
          value: "score",
        },
        {
          title: '及格分',
          value: "pass_score",
        },
        {
          title: '题目数量',
          value: "question_count",
        },
        {
          title: "考试时长(分钟)",
          value: 'exam_time',
        },
        {
          title: '状态',
          value: 'status',
          value_display: "status_alias",
          style_plan: [
            {
              value: "0",
              style: "color:#67C23A;"
            },
            {
              value: "-1",
              style: "color:#F56C6C;"
            }
          ]
        }
      ],
      // 表格列表数据
      questionBankList: [],
      // 分页信息
      pagingData: {
        is_show: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        offset: 0, // 分页的offset,序号列使用
      },
      // 抽屉数据
      drawerData: {
        visible: false,
        loading: true,
        loading_text: '玩命加载中……',
        title: '',
        width_height: '800px',
      },
      //抽屉表单
      formData: {
        name: "",
        difficulty: 1,
        description: "",
        pass_score: "",
        exam_time: "",
        status: '0',
        strategy: {}
      },
      // 表单验证
      rules: {
        name: [
          {required: true, message: '输入标题', trigger: 'blur'},
        ],
        difficulty: [
          {required: true, message: '请输入难度', trigger: 'blur'},
        ],
        status: [
          {required: true, message: '请选择题型状态', trigger: 'blur'}
        ],
        description: [
          {required: true, message: '请输入试卷描述', trigger: 'blur'}
        ],
        pass_score: [
          {required: true, message: '请输入及格分', trigger: 'blur'}
        ],
        exam_time: [
          {required: true, message: '请输入考试时长', trigger: 'blur'}
        ],
      },

      //难度
      difficultList: [
        {
          title: "容易",
          value: 1,
        },
        {
          title: "困难",
          value: 3
        }
      ],

      //题型列表
      typeList: [
        {
          id: "all",
          display_name: "全部题型"
        }, {
          id: "single_choice",
          display_name: "单选题"
        },
        {
          id: "multiple_choice",
          display_name: "不定项选择题"
        },
        {
          id: "case_analysis",
          display_name: "案例分析题"
        },
        {
          id: "essay_questions",
          display_name: "论述题"
        },
      ],

      searchForm: {},

      searchParams: {},

      buttonName: "隐藏",

      questionCount: 0,

      questionAllScore: 0,

      questionForm: [],

      isReadOnly: true,

      questionTypeList: []
    }
  },
  computed: {
    ...mapGetters(['userPermissions', 'buttonType', 'curChooseBankId', 'questionBankInfo', 'curChooseBankName'])
  },
  methods: {
    // 切换页码操作
    async switchPaging() {
      this.initTestPaperList(NO_DEL)
    },

    // 响应添加按钮
    async addButton() {
      await this.$api.getUnitExamBankList()


      this.isReadOnly = false;
      this.drawerData.loading_text = '玩命加载中……';
      this.drawerData.visible = true;
      this.drawerData.title = '添加模拟试卷';
      this.$nextTick(() => {
        // let id = this.curChooseBankId;
        this.formData.qb_id = this.curChooseBankId;
        this.getQuestionType(this.curChooseBankId);
        this.drawerData.loading = false
      });
    },

    // 响应编辑按钮
    async editButton(id) {
      this.drawerData.loading_text = '玩命加载中……';
      this.drawerData.visible = true;
      this.drawerData.title = '修改模拟试题';
      let {info} = await this.$api.getTestPaperInfo(id);
      this.$nextTick(() => {
        this.drawerData.loading = false;
        this.formData = info;
        // this.formData.exam_time = (this.formData.exam_time / 60) + "";
        this.formData.difficulty = Math.ceil(info.difficulty);
      });

    },

    //响应题型添加
    addQuestionButton(id) {
      let qbId = this.curChooseBankId;

      this.$router.push(this.$route.path + '/' + id + '/' + qbId + '/create');
    },

    // 处理抽屉关闭
    drawerClose() {
      this.resetFormData();
      this.isReadOnly = true;
      this.drawerData.visible = false;
      this.drawerData.loading = true
    },

    // 处理抽屉确认
    async drawerConfirm() {
      //调用组件的数据验证方法
      this.$refs['TestPaperForm'].validate((valid) => {
        if (valid) {
          this.formSubmit()
        } else {
          this.$message.error('数据验证失败，请检查必填项数据！')
        }
      })
    },

    // form数据提交，请求接口
    async formSubmit() {
      this.drawerData.loading_text = '玩命提交中……';
      this.drawerData.loading = true;
      // this.formData.exam_time = parseInt(this.formData.exam_time) * 60 + "";
      let id = this.formData.id;
      if (id) {
        delete this.formData.strategy;
      }

      // 调用接口
      let result = await this.$api.storeTestPaperList(this.formData);
      this.$nextTick(() => {
        this.drawerData.visible = false
      });
      this.$nextTick(() => {
        if (result) {
          this.initTestPaperList(NO_DEL)
        }
      });
      this.$nextTick(() => {
        this.$message.success('保存成功!')
      });
      this.resetFormData()
    },

    // 相应删除按钮
    deleteButton(id) {

      this.$confirm('是否删除该模拟试卷?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        let info = await this.$api.deleteTestPaper(id);
        if (info == 'ok') {
          this.$nextTick(() => {
            this.initTestPaperList(DEL);
          })
        } else {
          this.$message.error(info)
        }
      })
    },

    // 初始化数据
    resetFormData() {
      // 初始化form表单
      this.formData = {
        name: "",
        difficulty: 1,
        description: "",
        pass_score: "",
        exam_time: "",
        status: '0',
        strategy: {}
      };
      this.$nextTick(() => {
        this.$refs['TestPaperForm'].resetFields();
      })
    },

    async initTestPaperList(type, params = {}) {
      this.loadingStatus = true;
      let inputData = this.$refs['apeTable'].getPagingInfo(type);

      for (let param in params) {
        inputData[param] = params[param];
      }

      // let qbId = this.questionBankInfo.id;

      // if (!qbId) {
      //     // let name = window.location.origin + '/' + 'qb_info';
      //     let userInfo = JSON.parse(localStorage.getItem('user_info'));
      //     let name = WINDOW_LOCAL_ORIGIN + '_' + userInfo.id +'_'+'qb_info';
      //     let qbInfo = JSON.parse(localStorage.getItem(name));
      //     qbId = qbInfo.id;
      // }

      console.log('this.userBankChoice', this.curChooseBankId);

      inputData.qb_id = this.curChooseBankId;//题库ID


      let {list, pages} = await this.$api.getTestPaperList(inputData);
      this.questionBankList = [];
      this.$nextTick(() => {
        for (let question of list) {
          // question.exam_time = (question.exam_time / 60) + "分钟";
          this.questionBankList.push(question);
        }
      });

      this.pagingData.total = pages.total;
      this.pagingData.offset = pages.offset;
      this.loadingStatus = false
    },

    // 拖拽排序数据提交，请求接口
    async dragSortSubmit(orderIds) {
      let data = {
        ids: orderIds
      };
      let info = await this.$api.orderTestPaperList(data);
      if (info == 'ok') {
        this.$nextTick(() => {
          this.initTestPaperList(NO_DEL)
        })
      }
      this.$message.success('保存成功!')
    },

    // 表格拖拽排序，同层级移动有效果
    dragSort() {
      const el = document.querySelectorAll('.el-table__body-wrapper > table > tbody')[0];
      this.sortable = Sortable.create(el, {
        handle: ".drag-handle",
        setData: function (dataTransfer) {
          dataTransfer.setData('Text', '')
        },
        onEnd: () => {
          let Ids = [];
          let tmp = el.querySelectorAll('.drag-handle');
          for (let i = 0, len = tmp.length; i < len; i++) {
            Ids.push(tmp[i].dataset.id)
          }
          this.dragSortSubmit(Ids)
        },
      })
    },

    //搜索
    onSearchClick() {
      this.$refs['apeTable'].resetCurPageSize();
      this.initTestPaperList(DEL, this.searchForm);
    },

    //重置搜索
    onResetSearch() {
      this.searchForm = {};
      this.initTestPaperList();
    },

    //获取题型
    async getQuestionType(id) {
      this.questionTypeList = []
      let {info} = await this.$api.getQuestionBankInfo(id);
      this.questionTypeList = info.config;

      let questionTypeObject = {};
      for (let item of this.questionTypeList) {
        let questionName = item.question_type_name;
        let questionCode = item.question_type_code;
        if (item.checked && questionName && questionCode) {
          questionTypeObject[questionCode] = {
            each_score: 1,
            count: '',
            question_name: questionName,
          }
        }
      }

      this.formData.strategy = questionTypeObject;
    },

    setQuestionTypeCount(key) {
      if (this.questionTypeList) {
        let obj = this.questionTypeList.find(i => i.question_type_code === key)
        if (obj) {
          return obj.max_count
        }
      }

      return 0
    },

    resetQuestionTypeCount(key) {
      this.formData.strategy[key]["count"] = ""
      this.formData.strategy[key]["each_score"] = 1
    },

    onChangeQuestionBankConfirm() {
      console.log('test');
      this.initTestPaperList(DEL);
    },

    onQuestionInputCount(key, count, e) {
      console.log(key, count, e)
      if (e.length > 0) {
        var reg = /^[0-9]+.?[0-9]*$/;
        if (!reg.test(e)) {
          console.log("我不是数字", e)
          return;
        }
      }

      let v = parseInt(e)
      if (v > count) {
        this.formData.strategy[key]["count"] = count
      }
    }

  },

  mounted() {
    this.initTestPaperList(DEL);
    this.$nextTick(() => {
      this.dragSort()
    })
  },

  watch: {
    "$route.matched": function (n, o) {
      if (n.length === 2) {
        this.initTestPaperList(DEL);
      }
    },

    'formData.strategy': {
      handler(newVal) {

        let totalScore = 0;
        let totalCount = 0;
        for (let key in newVal) {
          let item = newVal[key];
          let count = item.count;
          let score = item.each_score * count;
          totalScore += score;
          if (count) {
            totalCount += parseInt(count);
          }
        }
        this.questionCount = totalCount;
        this.questionAllScore = totalScore;
      }, deep: true
    },

    'curChooseBankId'(newVal) {
      if (newVal) {
        this.initTestPaperList(DEL);
      }
    }
  },
}
</script>

<style lang="stylus" scoped>
.table-header
  margin-bottom 12px

.drag-handle
  font-size 24px
  cursor pointer

.el-input-group__prepend, .el-input-group__append
  background #ffffff
  padding 0 12px

.el-input-group__append
  color #ffffff
  background #1890ff

.el-popover .el-checkbox-group .el-checkbox
  margin-left 0px
  margin-right 12px

.el-radio.is-bordered
  width 100px

.el-color-picker
  position absolute

.font-color-blue
  color #1890ff

.font-color-grey
  color #999999

.text-pos-right
  text-align right

.input-box
  text-align center
  border-radius 3px
  margin-top 3px
  outline none
  border 1px solid #d9d9d9
  width 20px
  color #1890ff
</style>

<style lang="scss" scoped>
.x-row {
  display: flex;
  flex-direction: row;
  align-items: center
}
</style>